import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class JwtService {
  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  getToken() {
    return this.cookieService.get('token');
  }

  saveToken(token: string): void {
    this.cookieService.set('token', token, {path: '/'});
  }

  destroyToken() {
    isPlatformBrowser(this.platformId);
    {
      this.cookieService.delete('token', '/');
    }
  }
}
